var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page"},[_c('div',{staticClass:"login-table"},[_vm._m(0),_c('a-form',{staticClass:"login-form",attrs:{"id":"form-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                { required: true, message: 'Please input your username!' } ],
            } ]),expression:"[\n            'username',\n            {\n              rules: [\n                { required: true, message: 'Please input your username!' },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"Username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: 'Please input your Password!' } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: 'Please input your Password!' },\n              ],\n            },\n          ]"}],attrs:{"size":"large","type":"password","placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","size":"large","html-type":"submit"}},[_vm._v(" Log in ")])],1)],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/assets/images/logo.png"),"alt":""}})])}]

export { render, staticRenderFns }