<template>
  <div class="login-page">
    <div class="login-table">
      <div class="header">
        <img src="@/assets/images/logo.png" alt class="logo-img" />
      </div>
      <a-form
        id="form-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
      >
        <a-form-item>
          <a-input
            size="large"
            v-decorator="[
              'username',
              {
                rules: [
                  { required: true, message: 'Please input your username!' },
                ],
              },
            ]"
            placeholder="Username"
          >
            <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            size="large"
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: 'Please input your Password!' },
                ],
              },
            ]"
            type="password"
            placeholder="Password"
          >
            <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <!-- <a-checkbox
            v-decorator="[
              'remember',
              {
                valuePropName: 'checked',
                initialValue: true,
              },
            ]"
          >
            Remember me
          </a-checkbox>
          <a class="login-form-forgot" href="">
            Forgot password
          </a> -->
          <a-button
            type="primary"
            size="large"
            html-type="submit"
            class="login-form-button"
          >
            Log in
          </a-button>
          <!-- Or
          <a href="">
            register now!
          </a> -->
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import MD5 from "md5.js"
import { login, logout, users } from "@/api/modules/login"
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { username: "" })
  },
  mounted() {
    this.$store.commit("analysis/setLoading", false)
  },
  methods: {
    loginSys(params) {
      login(params)
        .then((data) => {
          if (data.status === 200 || data.status === 204) {
            let token = data.data.data.token
            localStorage.setItem("token", token)
            users(token)
              .then((res) => {
                this.$store.commit("analysis/setAuthenticated", true)
                this.$router.push({ name: "SIKB-heat-analysis" })
              })
              .catch((error) => {
                if (error.code === 401) {
                  this.$store.commit("analysis/setAuthenticated", false)
                  this.$router.push({ name: "Login" })
                }
              })
          }
        })
        .catch((error) => {
          this.$message.error(error.message || "请重试")
        })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          values.password = new MD5().update(values.password).digest("hex")
          this.loginSys(values)
        }
      })
    },
  },
}
</script>

<style lang="scss">
.login-form {
  padding: 0 40px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.login-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #f1f1f1;
  .login-table {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 420px;
    transform: translate(-60%, -60%);
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 4px 4px 20px #ccc, -4px -4px 20px #ccc;
    .header {
      margin: 30px 0;
      @include flex-contianer(row, flex-start, center);
      img {
        height: 70px;
        width: 50%;
      }
    }
    .form {
      padding: 20px 30px;
      .input {
        margin-bottom: 20px;
        height: 42px;
        background-color: #fff;
      }
      .submit-btn {
        width: 100%;
        height: 50px;
        font-size: 18px;
      }
    }
  }
}
</style>
